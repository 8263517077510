import styled from 'styled-components';
import {
	media,
	mediaQuery,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const Wrapper = styled.section`
	padding-top: ${props =>
		props.isLeadershipSection ? '' : !props.isUsecase ? '220px' : '70px'};
	padding-bottom: 50px;
	${mediaQuery.laptopXL`
		padding-top: ${props =>
			props.isLeadershipSection ? '' : !props.isUsecase ? '150px' : '70px'};
	`}
	${mediaQuery.tabletL`
			padding-bottom: ${props => (props.isLeadershipSection ? '0px' : '50px')}
	`}
	${mediaQuery.mobileXL`
		padding-top: ${props => (props.isLeadershipSection ? '' : '70px')};
		padding-bottom: ${props => (props.isLeadershipSection ? '' : '11px')}
	`};
`;

export const TitleWrapper = styled.div`
	padding-bottom: 45px;
	${mediaQuery.mobileXL`padding-bottom : 38px`};
`;

export const CustomerHolder = styled.div`
	display: grid;
	grid-template-columns: ${props =>
		props.isLeadershipSection
			? 'repeat(3, 1fr)'
			: !props.isUsecase
			? 'repeat(4, 1fr)'
			: 'repeat(3, 1fr)'};
	column-gap: 20px;
	row-gap: ${props => (!props.isUsecase ? '0px' : '70px')};
	> article {
		animation: ${props => (props.isUsecase ? 'fade-in 1s ease' : 'none')};
		transition: all 0.3s fade;
		@keyframes fade-in {
			from {
				transform: scale(0.9);
				opacity: 0;
			}
			to {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
	@media only screen and (max-width: 1155px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	${mediaQuery.mobileXL`>article{
		display: flex;
		justify-content: center
	}`}

	@media only screen and (max-width: 1155px) and (min-width: 768px) {
		> article {
			margin-top: 0px !important;
		}
		>article: nth-child(3n-1) {
			margin-top: ${props => (props.marginEffect ? '30px !important' : '0px')};
		}
	}

	@media only screen and (min-width: 768px) {
		>article: nth-child(${props => (!props.isUsecase ? 'even' : '3n+2')}) {
			margin-top: ${props => (props.marginEffect ? '30px' : '0px')};
		}
	}

	${mediaQuery.mobileXL`
	grid-template-columns: 1fr;
	row-gap: 30px;
	padding:0`}
`;

export const LeadershipTitle = styled.div`
	font-size: ${setFontSize.large};
	font-family: ${setFontFamily.black};
	text-align: center;
	margin-bottom: 30px;
	${media.phone`
		margin-bottom: 30px;
	`}
	${media.tablet`
		font-size: ${setFontSize.tinyMedium};
		margin-bottom: 70px;
	`}
	${media.desktop`
		font-size: ${setFontSize.mediumLarge};
		margin-bottom: 70px;
	`}
	${media.large`
		font-size: ${setFontSize.larger};
		margin-bottom: 70px;
	`}
`;
