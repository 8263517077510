import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	margin-right: 20px;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 15px 15px 32px 14px;
	/* box-shadow: -1px 0px 21px 0px rgba(219, 219, 219, 0.75);
	-webkit-box-shadow: -1px 0px 21px 0px rgba(219, 219, 219, 0.75);
	-moz-box-shadow: -1px 0px 21px 0px rgba(219, 219, 219, 0.75); */
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
	width: 100%;
	max-width: ${props => (props.data ? '325px' : '')};
	max-height: ${props => (props.isUsecase ? '100%' : '500px')};
	min-height: ${props => (props.data ? '' : '470px')};
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;
	position: relative;
	color: ${setColors.lightBlack};
	text-decoration: none;
	background-color: ${props => (props.data ? '#f5f5f5' : '')};
	&:active {
		pointer-events: all;
	}

	transition: all 0.2s ease-in-out;
	-webkit-tap-highlight-color: transparent;
	&:hover {
		transform: ${props => (props.isUsecase ? 'scale(1.07)' : 'scale(1.1)')};
		color: ${props =>
			props.isClickable ? setColors.themeBlue : setColors.lightBlack};
	}
`;

export const TeamMemberName = styled.div`
	font-size: ${setFontSize.mediumSmall};
	font-family: ${setFontFamily.bold};
	text-align: center;
	margin-top: 15px;
	${mediaQuery.laptopXL`
		font-size: ${setFontSize.smaller};
	`}
	${mediaQuery.laptopL`
		font-size: ${setFontSize.tinySmall};
	`}
	${mediaQuery.laptopM`
		font-size: ${setFontSize.verySmall};
	`}
	${mediaQuery.laptopSM`
		font-size: ${setFontSize.tiny};
	`}
	${mediaQuery.laptopXS`
		font-size: ${setFontSize.mediumTiny};
	`}
	${mediaQuery.tabletM`
		font-size: ${setFontSize.veryTiny};
	`}
	${mediaQuery.tabletXS`
		font-size: ${setFontSize.mediumSmall};
	`}
`;

export const TeamMemberTitle = styled.div`
	font-size: ${setFontSize.mediumTiny};
	font-family: ${setFontFamily.book};
	text-align: center;
	margin-top: 15px;
	${mediaQuery.laptopXL`
		font-size: ${setFontSize.mediumTiny};
	`}
	${mediaQuery.laptopL`
		font-size: ${setFontSize.mediumTiny};
	`}
	${mediaQuery.laptopM`
		font-size: ${setFontSize.littleTiny};
	`}
	${mediaQuery.laptopSM`
		font-size: ${setFontSize.veryTiny};
	`}
	${mediaQuery.laptopXS`
		font-size: ${setFontSize.veryVeryTiny};
	`}
	${mediaQuery.tabletM`
		font-size: ${setFontSize.tiniest};
	`}
	${mediaQuery.tabletXS`
		font-size: ${setFontSize.mediumTiny};
	`}
`;

export const SocialHandles = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

export const StyledAnchor = styled.a`
	margin-right: 15px;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: #ffffff;
	position: relative;

	svg {
		&:hover {
			color: ${props =>
				props.linkedIn
					? 'rgb(2, 112, 173) !important'
					: 'rgb(67, 156, 214) !important'};
		}
	}
`;
