import React from 'react';
import Img from 'gatsby-image';
import { IconNext } from '../icons/index';
import { SetContainerWidth } from '../../../../utils/helpers';
import * as S from './styles';
import TeamMemberSnippet from '../TeamMemberSnippet/TeamMember';
import { CONSTANTS } from '../../../../utils/constants';

const imageStyle = {
	objectFit: 'contain',
	width: SetContainerWidth.fullWidth,
	borderRadius: '10px',
	height: 'auto',
};

const logoWrapperStyle = {
	margin: '0px auto',
	width: SetContainerWidth.fullWidth,
	height: SetContainerWidth.fullWidth,
	minWidth: SetContainerWidth.fullWidth,
	maxWidth: SetContainerWidth.fullWidth,
	minHeight: '79px',
};

const CustomerSnippet = ({
	image,
	logo,
	title,
	slug,
	id,
	isUsecase = false,
	data,
	isPopular,
}) => {
	return data ? (
		<TeamMemberSnippet image={image} data={data} />
	) : (
		<article>
			<S.Wrapper
				to={`${!slug ? '' : `/${slug}/`}`}
				id={id}
				isUsecase={isUsecase}
				isClickable={!slug ? false : true}
				data={data}
			>
				{isPopular && (
					<>
						<S.PopularBadge>{CONSTANTS.POPULAR_BADGE}</S.PopularBadge>
						<S.PopularText>{CONSTANTS.POPULAR_TEXT}</S.PopularText>
					</>
				)}
				<Img
					fluid={image.fluid}
					style={{ width: '100%' }} // for image wrapper cls (gatsby-image-wrapper)
					imgStyle={imageStyle} // for actual img within picture tag
					alt={image.title}
				/>
				{logo && (
					<div style={{ width: '200px' }}>
						<Img
							fixed={logo.fixed}
							style={logoWrapperStyle}
							imgStyle={{
								objectFit: 'none',
								width: '100%',
							}}
							alt={logo.title}
						/>
					</div>
				)}
				<p>{title}</p>
				{slug && (
					<span
						style={{
							position: 'absolute',
							bottom: isUsecase ? '16px' : '13px',
							right: '15px',
						}}
					>
						<IconNext height={17} width={24} />
					</span>
				)}
			</S.Wrapper>
		</article>
	);
};

export default CustomerSnippet;
