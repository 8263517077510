import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { keyframes } from 'styled-components';
import {
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../../utils/helpers';

export const Article = styled.article`
	display: flex;
	justify-content: center;
`;

export const Wrapper = styled(Link)`
	padding: 8px;
	box-sizing: border-box;
	border-radius: 10px;
	/* box-shadow: -1px 0px 21px 0px rgba(219, 219, 219, 0.75);
	-webkit-box-shadow: -1px 0px 21px 0px rgba(219, 219, 219, 0.75);
	-moz-box-shadow: -1px 0px 21px 0px rgba(219, 219, 219, 0.75); */
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
	width: 100%;
	max-width: ${props =>
		props.data ? '325px' : props.isUsecase ? '352px' : '250px'};
	max-height: ${props => (props.isUsecase ? '100%' : '470px')};
	min-height: ${props => (props.data ? '' : '470px')};
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;
	position: relative;
	color: ${setColors.lightBlack};
	text-decoration: none;
	background-color: ${props => (props.data ? '#f5f5f5' : '')};
	&:active {
		pointer-events: ${props => (props.isClickable ? 'all' : 'none')};
	}
	p {
		padding: 16px 33px 0px 15px;
		padding-bottom: ${props => (props.isUsecase ? '16px' : '0px')};
		line-height: 1.4;
		margin: 0;
		font-size: ${setFontSize.mediumTiny};
	}
	span {
		display: flex;
		color: ${setColors.themeBlue};
		justify-content: flex-end;
		font-size: 2rem;
	}

	div {
		display: flex;
		justify-content: center;
	}
	div:nth-of-type(1) {
		margin-bottom: 1px;
	}

	transition: all 0.2s ease-in-out;
	-webkit-tap-highlight-color: transparent;
	&:hover {
		transform: ${props => (props.isUsecase ? 'scale(1.07)' : 'scale(1.1)')};
		color: ${props =>
			props.isClickable ? setColors.themeBlue : setColors.lightBlack};
	}
`;

export const CoverImage = styled(Img)`
	img:nth-child(2) {
		border-radius: 10px;
	}
	height: 234px;
`;

const spin = keyframes`
	0% {
    	rotate: 0deg;
 	}
  	100% {
    	rotate: 180deg;
  	}
`;

export const PopularBadge = styled.div`
	position: absolute;
	top: -40px;
	right: -23px;
	z-index: 1;
	animation: ${spin} 4s linear infinite;
`;

export const PopularText = styled.div`
	position: absolute;
	rotate: -22deg;
	top: 2px;
	right: -8.5px;
	z-index: 2;
	color: ${setColors.black};
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.littleTiny};
`;
