import React from 'react';
import styled from 'styled-components';
import Partner from '../../../svg/partner.inline.svg';
import { CenterHeader } from '../customers/styles';

const Wrapper = styled.div`
	text-align: center;
	padding: 0 15px;

	svg {
		font-size: 0.5rem;
		height: 60px;
	}
`;

const Title = ({ title, partner, about }) => {
	title = title.split('.');
	return (
		<Wrapper>
			{partner && <Partner />}
			<CenterHeader lineHeight="1.2" about={about}>
				{title[1] ? [`${title[0]}.`, <br />, title[1]] : title}
			</CenterHeader>
		</Wrapper>
	);
};

export default Title;
