import React from 'react';
import Img from 'gatsby-image';
import { AiOutlineTwitter } from 'react-icons/ai';
import { TiSocialLinkedin } from 'react-icons/ti';
import * as S from './styles';
import { setColors, SetContainerWidth } from '../../../../utils/helpers';

const imageStyle = {
	objectFit: 'contain',
	width: SetContainerWidth.fullWidth,
	borderRadius: '10px',
	height: 'auto',
};

const TeamMemberSnippet = ({ image, data }) => {
	return (
		<article style={{ margin: '20px' }}>
			<S.Wrapper data={data}>
				<Img
					fluid={image.fluid}
					style={{ width: '100%' }} // for image wrapper cls (gatsby-image-wrapper)
					imgStyle={imageStyle} // for actual img within picture tag
					alt={image.title}
				/>
				{data && (
					<>
						<S.TeamMemberName>{data.name}</S.TeamMemberName>
						<S.TeamMemberTitle>{data.title}</S.TeamMemberTitle>
						<S.SocialHandles>
							<S.StyledAnchor
								href={data?.linkedin}
								target="_blank"
								className="linkedin"
								linkedIn
							>
								<TiSocialLinkedin
									style={{
										position: 'absolute',
										color: `${setColors.planCardGrey}`,
										left: '5px',
										top: '4px',
									}}
								/>
							</S.StyledAnchor>
							<S.StyledAnchor
								href={data?.twitter}
								target="_blank"
								className="twitter"
								twitter
							>
								<AiOutlineTwitter
									style={{
										position: 'absolute',
										color: `${setColors.planCardGrey}`,
										left: '5px',
										top: '4px',
									}}
								/>
							</S.StyledAnchor>
						</S.SocialHandles>
					</>
				)}
			</S.Wrapper>
		</article>
	);
};

export default TeamMemberSnippet;
